<template>
  <div class="main">
    <div class="ant-spin-nested-loading" style="width: 100%">
      <div class="ant-spin-container">
        <div id="rollDetail" style="">
          <div class="top-box">
            <div class="roomMessage">
              <div class="imgMessage">
                <div class="leftImg">
                  <div
                    class="avatar-container roll-room__avatar"
                    style="width: 100%"
                  >
                    <div class="avatar avatar_status-official">
                      <div
                        class="avatar__picture "
                        :style="{backgroundImage:'url('+indexdata.logo+')'}"
                        :class="{'avatar__pictures':indexdata.title.indexOf('官方') != -1 || indexdata.title.indexOf('cxskins') != -1}"
                      ></div>
                    </div>
                    <p class="avatar__picture_p" v-if="indexdata.title.indexOf('官方') != -1 || indexdata.title.indexOf('cxskins') != -1">官方</p>
                  </div>
                </div>
                <div class="rightText">
                  <p class="name">{{indexdata.title}}</p>
                  <span>开奖时间</span>
                  <p class="time">{{indexdata.end_time}}</p>
                </div>
              </div>
              <div class="border"></div>
              <div class="centerDesc">
                <p>活动介绍</p>
                <p class="text" v-html="$options.filters.msg(contents)">
                </p>
              </div>
              <div class="bottomButton" >
                <div v-if="!isparticipateRoll">
                <div style="margin-bottom:10px;" v-if="!isparticipateRoll">
                <el-input v-if="indexdata.passwd == 1 && currentTime < startTime"
                  placeholder="请输入密钥"
                  prefix-icon="el-icon-s-cooperation"
                  v-model="inputtxt">
                </el-input>
                </div>
                <button class="enter" @click="participateRoll" v-if="currentTime > indexdata.start_time && currentTime < startTime">
                  <span>立即参与</span>
                </button>
                </div>

                <button class="enter" style="background:#666" v-if="currentTime > startTime">
                  <span>已结束</span>
                </button>
                <button class="enter" style="background:#ff9939" v-if="isparticipateRoll && currentTime < startTime">
                  <span>已参加</span>
                </button>
                <button class="enter" style="background:#666" v-if="currentTime < indexdata.start_time">
                  <span>未开启</span>
                </button>
              </div>
            </div>
            <div class="allPrizeBox">
              <div class="title">
                <span class="head"
                  ><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAWCAYAAADeiIy1AAADLklEQVRIS+1VW2gUZxT+zj+7OzNuttnEjJFag5fsRqnipbRe24o+JPpQSim0WsEbgo0KBqRVaZA2SFBKI2oXSig24K0gPoiIKTTxoVqIpYJtQdcN8YJisjHZuLvO7s7uf2RGrbuu+CCLvnhgmPnP+f7zzTlz/m8IT9kEP/wel/oNINaAeBSDTrFpNkYSiD6NtdeTK/XxisI/gbGEiWKQ3H7PlWrt70cyH0/5i7oq+Ji0CwDVqR4lrmkiNXLPMgD8EY6aHwKQ+fi3AY9l6H8BmF5R7ulPJK0yK8teBi5y1JwXAdKP8QVEtaPVL4UQoQ/mVcf27ZrrVT3C9em67pH/rsT8ktEUGTT35hMFqrTviKh5zjvGcMe+hRWJpJVu/PrPTM/Fuz4wlocHzWPPJAoa2g6Adm1tnDa8fmWwwgb13UzEly7/zc2Sc5J4RmQg3Wv7A4Z7JrHS4/aITNfxBh5j6GW2//vQv/H2Q2EfpNwYvpsOFRDVVqr1pIg6MKYQYYOuuxKffzxxlGXlsvfNXOb8hQFxZ8D0gtEjgaP2ZkFYa7es5q0y871Zo3OaqrhVVXF3/BpJZbOsg9HGhOuck1ciQ+lOClTpbUTY8qwPXSofM/ZS0NBS5T6f2ty0HoII2VwO8cR9CEEgengJIZyY/awoAsyAlBLMDMkMlo/uLCEl4w2fF4oQTqylrR0j8XiagobOE2vG4czhA6UqoCBPwxeb0HfjFl4qUdTjdlV9VL/IKdduTynMbmtOSpzsPIuMlR20K/oM4J8B8nrcbmQsqxQ8eJKLkwCtc16/uhrecqknasaNxS9t3xYRnTl7HntCHVjwbg1atk114i0/XEb3uev4qnEVGhbNL9qzumknbty6gxFhltly9H+fnjcUJ053YXvrfix5fxJCu2c6SZuaL+H07xG0bt+MT5YtLiJ6PAThqOlwvCZ63Tq82mGYMP5NdB75sWhUX2S861dsxLWbt4srChjaVUGidvb0KY4U5Vt0KOYIo79cQ3CS839DpC+JoZgJW5CNSn8B3paev/+5DGbZG46magvO0eQK1wLhch0kUKAUGsTgqzJLa3qHzXN2vgebU+GCLJKAmAAAAABJRU5ErkJggg=="
                    alt=""
                  />活动奖池</span
                >
                <template v-if="indexdata.data">
                <p style="font-size: 18px">
                  共 <i>{{indexdata.data.count}}</i> 件<span class="allPrice"
                    >总价值：<i>{{indexdata.data.sumCoin}}</i></span
                  >
                </p>
                </template>
              </div>
              <div class="listBox">
                <div class="mobile_need_box">
                  <ul>
                    <!-- 添加奖品处 -->
                    <li v-if= "isnametype && nametype == 1" class="heart sssr" style="justify-content: center;cursor:pointer;" @click="prizeAdd()">
                      <div class="imgBox roll_color5">
                        <i class="iconfont icon-jiahaocu" style="font-size:50px;color: #666;"></i>
                      </div>
                      <p class="price">添加奖品</p>
                    </li>
                    <li class="heart sssr" v-for="(items,indexs) in indexdata.data" :key="indexs" v-if="indexs < indexdata.data.count">
                      <div class="imgBox roll_color5">
                        <img
                          :src=items.logo
                          alt=""
                        />
                      </div>
                      <p class="name">{{items.title}}</p>
                      <p class="price">{{items.coin}}</p>
                    </li>
                    <!-- 添加奖品页面 -->
                    <prizeAdd v-if="isprizeAdd" :isprizeAdd.sync="isprizeAdd" @closepop="closepop"></prizeAdd>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="time" v-if="currentTime < startTime">
            <span>倒计时：</span>
            <div class="ant-statistic">
              <div class="ant-statistic-content">
                <span class="ant-statistic-content-value"
                  >{{ tt }} 天 {{ hh }} 时 {{ mm }} 分 {{ ss }}秒</span
                >
              </div>
            </div>
          </div>
          <div class="time" v-else>
            <div class="ant-statistic">
              <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">已经结束啦!!!</span>
              </div>
            </div>
          </div>
          <!---- 中奖详情 ---->
           <div class="allPlayerBox" v-if="currentTime > startTime">
            <div class="title" >
              <span>获胜者</span>
              <template v-if="indexdata.data">
              <p>共 <i>{{winData.length}}</i> 人</p>
              </template>
            </div>

            <div class="allPlayer height" v-if="winData.length != 0">
              <ul class="clearfixs">
                <div class="Box"  v-for="(items,indexs) in winData" :key="indexs">
                  <li style="margin-top: 25px; float: left;margin-left: 7px;">
                    <img
                      :src=items.logo
                      alt=""
                      class="avatar"
                    />
                    <p class="name">{{ items.title }}</p>

                  </li>
                  <li style="margin-top: 25px;float: right; margin-right: 7px;">
                    <img
                      :src=items.uLogo
                      alt=""
                      class="avatar"
                    />
                    <p class="name">{{items.username}}</p>

                  </li>
                </div>

              </ul>
            </div>
            <div v-else>
              <p style="text-align:center;padding: 25px;">{{ msg }}</p>

            </div>
            <!-- <p class="pageP"><ul class="ivu-page"><li title="上一页" class="ivu-page-prev ivu-page-disabled"><a>
              <i class="ivu-icon ivu-icon-ios-arrow-back"></i></a></li> <li title="1" class="ivu-page-item ivu-page-item-active">
                <a>1</a></li><li title="下一页" class="ivu-page-next ivu-page-disabled"><a>
              <i class="ivu-icon ivu-icon-ios-arrow-forward"></i></a>
            </li></ul></p> -->
            <!-- 暂时不需要分页 -->
            <p class="pageP" style="display: none;">
              <ul class="ivu-page">
                <li title="1" class="ivu-page-item ivu-page-item-active">
                  <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    @current-change="pagedata"
                    :total="total"
                  >
                  </el-pagination>
                </li>
              </ul>
            </p>
          </div>
          <!---->
          <div class="allPlayerBox">
            <div class="title" >
              <span>参与用户</span>
              <template v-if="indexdata.data">
              <p>共 <i>{{indexdata.user.count}}</i> 人</p>
              </template>
            </div>
            <div class="allPlayer height">
              <ul class="clearfix clearfixs">
                <!-- <li>
                  <img
                    src="http://api.r8skins.com/assets/a.jpg"
                    alt=""
                    class="avatar"
                  />
                  <p class="name">123</p>
                </li> -->
                <li v-for="(items,indexs) in indexdata.user" :key="indexs">
                  <div v-if="indexs < indexdata.user.count">
                    <img
                    :src=items.logo
                    alt=""
                    class="avatar"
                  />
                  <p class="name">{{items.username}}</p>
                  </div>

                </li>
              </ul>
            </div>
            <!-- <p class="pageP"><ul class="ivu-page"><li title="上一页" class="ivu-page-prev ivu-page-disabled"><a>
              <i class="ivu-icon ivu-icon-ios-arrow-back"></i></a></li> <li title="1" class="ivu-page-item ivu-page-item-active">
                <a>1</a></li><li title="下一页" class="ivu-page-next ivu-page-disabled"><a>
              <i class="ivu-icon ivu-icon-ios-arrow-forward"></i></a>
            </li></ul></p> -->
            <!-- 暂时不需要分页 -->
            <p class="pageP" style="display: none;">
              <ul class="ivu-page">
                <li title="1" class="ivu-page-item ivu-page-item-active">
                  <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    @current-change="pagedata"
                    :total="total"
                  >
                  </el-pagination>
                </li>
              </ul>
            </p>
          </div>
        </div>
        <!---->
      </div>
    </div>

    <!-- 红包 -->
    <Redenvelopes></Redenvelopes>
  </div>
</template>

<script>
import Redenvelopes from "../components/Redenvelopes";
import prizeAdd from "../components/prizeAdd";
import { Input  } from 'element-ui';

export default {
  components: {
    Redenvelopes,
    prizeAdd,
    "el-input":Input,
  },
  data() {
    return {
      //服务器时间, 在组件中重新赋值, 可接受字符串或时间戳格式的日期时间值
      currentTime: Date.parse(new Date()),
      //倒计时结束时间, 在组件中重新赋值，可接受字符串或时间戳格式的日期时间值
      startTime: Date.parse(new Date()),
      // startTime: Date('2020/05/18 12:20:30').getTime(),
      //倒计时实时结果对象, 在组件中watch进行业务处理、个性化样式
      countdownTime: {},
      //倒计时
      timeCounter: null,
      tt: "",
      hh: "",
      mm: "",
      ss: "", //倒计时
      isitme: true, //时间是否结束
      total:10*10,//页数
      isparticipateRoll:false,//是否参与roll
      inputtxt:'',//输入的密码
      indexdata:{},//页面数据
      contents:"",//过滤器
      winData:[],//roll房开奖数据
      msg:"正在努力开奖中，请耐心等待。（小提示：若等待过久，请刷新一下页面哦）",
      nametype:0,//用户权限
      nametypes:1,//奖品数量
      isnametype:false,//如果存在奖品 不可以继续添加
      isprizeAdd:false,//添加奖品页面
      isgetdata:1,//当添加奖品完毕后 监听刷新页面数据
    };
  },
  methods: {
    closepop(){
      this.getdata()
    },
    //获取页面数据
    getdata(){
      this.$axios({
        url:'/roll/getRoll',
        method:'post',
        params:{
          rid :this.$route.query.id
        }
      }).then( res => {
        if(res.data.status == 200){
          this.indexdata = res.data.data
          this.nametype = res.data.owner;
          this.nametypes = res.data.data.data.count
          //判断是否有商品
          if(this.nametypes == 0){
            this.isnametype = true
          }else{
            this.isnametype = false
          }
          //兼容ios获取时间戳
          // this.startTime = res.data.data.end_time
          let thedata=res.data.data.end_time.replace(/\-/g, "\/")
          let thedatas=res.data.data.start_time.replace(/\-/g, "\/")
          // parseInt(new Date(thedata).getTime())
          //兼容写法
          // Date.parse(new Date(thedata))||Date.parse(new Date(thedata))
          // this.startTime = new Date(res.data.data.end_time).getTime()
          this.startTime = Date.parse(new Date(thedata))
          this.indexdata.start_time = Date.parse(new Date(thedatas))
          this.contents = res.data.data.info

          if(this.currentTime > this.startTime){
            this.getWinData();//拉取抽奖数据
          }
        }else{
          this.$message({
              type: 'error',
              showClose: true,
              message:res.data.msg
          });
        }
      })
    },

    //获取获胜者数据
    getWinData(){
      this.$axios({
        url:'/roll/win/data',
        method:'post',
        params:{
          rid :this.$route.query.id
        }
      }).then ( res => {
        if(res.data.status == 200){
          //成功
          this.winData = res.data.data;



        }else{
          this.msg = res.data.msg;
        }
      })
    },
    //查询用户是否参加roll
    getuserroll(){
      this.$axios({
        url:'/roll/if/getadd',
        method:'post',
        params:{
          rid :this.$route.query.id
        }
      }).then ( res => {
        if(res.data.status == 200){
          if(res.data.msg == 1){
            this.isparticipateRoll = true
          }else{
            this.isparticipateRoll = false
          }
        }else{
          this.$message({
              type: 'error',
              showClose: true,
              message:res.data.msg
          });
        }
      })
    },
    // 红包
    redPrize() {
      document.getElementsByClassName("ivu-modal")[0].style.display = "block";
      document.getElementById("isquan").classList.add("backghui");
    },
    //倒计时
    filt(val) {
      return val > 9 ? val : "0" + val;
    },
    //倒计时
    _timeCallback(offset) {
      (this.tt = this.filt(Math.floor(offset / 1000 / 60 / 60 / 24))),
        (this.hh = this.filt(Math.floor((offset / 1000 / 60 / 60) % 24))),
        (this.mm = this.filt(Math.floor((offset / 1000 / 60) % 60))),
        (this.ss = this.filt(Math.floor((offset / 1000) % 60)));
      // let data = {
      //     tt: this.filt(Math.floor(offset / 1000 / 60 / 60 / 24)),
      //     hh: this.filt(Math.floor((offset / 1000 / 60 / 60) % 24)),
      //     mm: this.filt(Math.floor((offset / 1000 / 60) % 60)),
      //     ss: this.filt(Math.floor((offset / 1000) % 60))
      // };
      // this.countdownTime = data;
    },
    //倒计时
    _down(val) {
      const interval = 1000; //每次间隔ms数
      let ms = val; // 从服务器和活动开始时间计算出的时间差，这里测试用 50000 ms
      let count = 0; //倒计时次数
      const startTime = new Date().getTime(); //此值用于矫正偏差用

      const countDownStart = (callback) => {
        count++;
        const offset = new Date().getTime() - (startTime + count * interval); // A
        let nextTime = interval - offset;
        if (nextTime < 0) {
          nextTime = 0;
        }
        ms -= interval;
        //console.log(`误差：${offset} ms`);

        //倒计时终止
        if (ms < 0) {
          this.isitme = false;
          clearTimeout(this.timeCounter);
          if (typeof this.timeover === "function") {
            this.timeover();

            setTimeout(function (){
              //给服务器开奖的延迟，一般服务器会在2秒内开出奖品
              this.getWinData();//拉取抽奖数据
            }, 3000);

          }
        } else {
          callback && callback(ms);
          this.timeCounter = setTimeout(() => {
            countDownStart(callback);
          }, nextTime);
        }
      };

      if (ms >= 0) {
        this.timeCounter = setTimeout(() => {
          countDownStart(this._timeCallback);
        }, interval);
      }
    },
    //拿到当前页数
    pagedata(key){
      // console.log(key);
    },
    //参加roll
    participateRoll(){
      this.$axios({
        url:'/roll/add',
        method:'post',
        data:{
          rid :this.$route.query.id,
          passwd:this.inputtxt
        }
      }).then( res => {
        if(res.data.status == 200){
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
          this.getdata()
          this.isparticipateRoll = true
        }else if(res.data.status == 100){
          this.open(res.data.msg)
        }else{
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      })
    },
    //花费参加roll
    open(msg){
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        iconClass:"el-icon-s-finance"
      }).then(() => {
        this.$axios({
          url:'/roll/AddCoin',
          method:"post",
          data:{
            rid :this.$route.query.id,
          }
        }).then( res => {
          if(res.data.status == 200){
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success",
            });
            this.isparticipateRoll = true
          }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        });
      });
    },
    //添加奖品
    prizeAdd(){
      if(this.currentTime < this.startTime){
        this.isprizeAdd = true
      }else{
        this.$message({
          showClose: true,
          message: 'Roll房已经结束啦!',
        });
      }
    }
  },
  computed: {
    _offset: function () {
      return this._end - this._now;
    },
    _end: function () {
      if (typeof this.startTime === "string") {
        return new Date(
          Date.parse(this.startTime.replace(/-/g, "/"))
        ).getTime();
      } else if (typeof this.startTime === "number") {
        return new Date(this.startTime).getTime();
      }
      return new Date(this.startTime).getTime();
    },
    _now: function () {
      if (typeof this.currentTime === "string") {
        return new Date(
          Date.parse(this.currentTime.replace(/-/g, "/"))
        ).getTime();
      } else if (typeof this.currentTime === "number") {
        return new Date(this.currentTime).getTime();
      }
      return new Date(this.currentTime).getTime();
    },
    //html过滤器
    // contents(msg){
    //    return msg
    //     .replace(msg ? /&(?!#?\w+;)/g : /&/g, '&amp;')
    //     .replace(/&lt;/g, "<")
    //     .replace(/&gt;/g, ">")
    //     .replace(/&quot;/g, "\"")
    //     .replace(/&#39;/g, "\'");
    // },

  },
  //html过滤器
   filters: {
    msg:function (msg) {
      return  msg
        .replace(/&amp;/g,'&')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#39;/g, "\'");
    },
    //过滤器 中间的显示为***
    testfile (value) {
      const start = value.slice(0,2)
      const end = value.slice(-2)
      return `${start}***${end}`
    }

  },
  watch: {
    _offset: {
      handler: function (vel) {
        vel > 0 && this._down(vel);
      },
      immediate: true,
    },
  },
  destroyed() {
    clearTimeout(this.timeCounter);
    this.timeCounter = null;
  },
  mounted() {
    //判断是否结束
    var a = new Date(this.startTime).getTime();
    if (a < this.currentTime) {
      this.isitme = false;
    } else {
      this.isitme = true;
    }
  },
  created(){
    this.getdata()
    this.getuserroll()



  },
};
</script>

<style src="../assets/csscopy/rollDetail.css"  scoped></style>
<style scoped lang='less'>
/* 解决ios无法输入的问题 */
// *:not(input,textarea) {
//     -webkit-touch-callout: text !important;
//     -webkit-user-select: text !important;
// }
// @import url("../assets/csscopy/rollDetail.css");
/deep/ .active{
  background-color: #409EFF !important;
}
.clearfixs{
  height: 390px !important;
  overflow-y: scroll !important;

}
.avatar__pictures{
  border-radius: 50% !important;
  border: 1px #E9B10E solid !important;
}
.avatar__picture_p{
  position: absolute;
  bottom: -5px;
  left:calc(50% - 20px);
  background-color: #E9B10E;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 50px;
  color: #000;
}
@media screen and (max-width: 800px) {
  #rollDetail {
    width: 100% !important;
  }
  .top-box {
    padding: 0 10px;
    flex-direction: column;
  }
  .roomMessage {
    width: 100% !important;
  }
  .allPrizeBox {
    width: 100% !important;
  }
  .allPrice {
    margin-left: 0 !important;
  }
  .head {
    font-size: 12px !important;
  }
  .heart {
    width: 100% !important;
  }
  .allPlayerBox {
    width: 95% !important;
  }
  .clearfix {
    li {
      width: 25% !important;
      .name {
        width: 95% !important;
        font-size: 14px !important;
      }
    }
  }
  .ivu-page{
    padding-right: 10%  !important;
  }
  .border{
    width: 100% !important;
  }
}
</style>
