<template>
  <div class="backgHui">
      <div class="big">
        <div class="prize_title">
            <h1>添加奖品</h1>
            <i class="iconfont icon-guanbi" @click="outadd"></i>
        </div>
        <div class="prize_data">
            <div class="prize_data_stay">
                <div class="prize_data_stay_adddata">
                    <h1>预添加奖品:</h1>
                    <p>已选奖品: <span>{{addProzeData.length}}</span>件,价值: <span style="color: rgb(255, 153, 57);">{{prices}}</span></p>
                </div>
                <ul class="prize_data_stay_ul">
                    <li class="prize_data_stay_li" v-for="(item,index) in addProzeData" :key="index">
                        <div :style="{backgroundImage:'url('+item.back_logo+')'}" class="prize_data_stay_img">
                            <img :src=item.logo>
                        </div>
                        <p>{{item.title}}</p>
                        <span>{{item.money}}</span>
                        <el-button size="mini" @click="remPrize(item,index)">移除</el-button>
                    </li>
                </ul>
            </div>
            <div class="prize_data_add">
                <h1>库存:</h1>
                <ul class="prize_data_add_ul">
                    <li class="prize_data_add_li" v-for="(itema,indexa) in checkboxList" :key="indexa">
                        <div :style="{backgroundImage:'url('+itema.back_logo+')'}" class="prize_data_stay_img">
                            <img :src=itema.logo>
                        </div>
                        <p>{{itema.title}}</p>
                        <span>{{itema.money}}</span>
                        <el-button size="mini" @click="addPrize(itema,indexa)">添加</el-button>
                    </li>
                </ul>
            </div>
            <!-- 分页 -->
            <div style="display: flex;justify-content: flex-end;">
                <el-pagination
                small
                background
                layout="prev, pager, next"
                @current-change="pagedata"
                :total="total"
                :page-size="limit"
                >
                </el-pagination>
            </div>
        </div>
        <div style="text-align: center;padding: 20px 0;">
            <el-button type="primary" @click="addComplete" v-if="isrepages">完成</el-button>
            <el-button type="primary" v-else>提交中<i class="el-icon-loading"></i></el-button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            addProzeData:[],//要添加的商品
            addProzeDatawid:[],//要添加的商品的id
            checkboxList:[],//库存
            total:0,//多少条数据
            page:1,//分页
            limit:12,//一页多少条
            equallyData:[],//一样的数据 要删除的
            isrepages:true,//是否在提交中 避免一直发请求
        };
    },
    props:['isprizeAdd'],
    computed:{
        //计算商品价格
        prices(){
            var prices = 0
            for(let i = 0;i < this.addProzeData.length;i++){
                prices += parseFloat(this.addProzeData[i].money)
            }
            return prices.toFixed(2)
        }
    },
    methods:{
        outadd(){
            this.$emit('update:isprizeAdd', false);
            this.addProzeData = []
        },
        //获取库存
        getdatare(){
        this.$axios({
            url:'win/get/list',
            method:'post',
            data:{
                page:this.page,
                limit:this.limit,
                type:0
            }
        }).then( res => {
            //因为有没有数据返回的status不一样
            if(res.data.status == 200){
                this.total = res.data.count
                //要去除已添加的奖品
                if(this.addProzeData.length > 0){
                    this.equallyData = []
                    this.checkboxList = res.data.data
                    // console.log(this.checkboxList);
                    // console.log(this.addProzeData);
                    for(let i =0;i<res.data.data.length;i++){
                        for(let j = 0;j<this.addProzeData.length;j++){
                            if(this.checkboxList[i].w_id === this.addProzeData[j].w_id){
                                // this.checkboxList.splice(i,1)
                                this.equallyData.push(i)
                                // console.log(i);
                                // console.log(this.equallyData);
                            }
                        }
                    }
                    // console.log(this.equallyData);
                    for (let i = this.equallyData.length - 1; i >= 0; i--) {
                        this.checkboxList.splice(this.equallyData[i],1)
                    }
                    
                }else{
                    this.checkboxList = res.data.data
                }
            }
        })
        },
        //页数
        pagedata(key){
            this.page = key
            this.getdatare()
        },
        //添加
        addPrize(item,index){
            this.checkboxList.splice(index,1)
            this.addProzeData.push(item)

        },
        //移除
        remPrize(it,ind){
            this.addProzeData.splice(ind,1)
            this.checkboxList.push(it)
        },
        //完成
        addComplete(){
            // for(let i =0;i < this.addProzeData.length;i++){
            //     console.log(this.addProzeData[i].w_id);
            // }
            this.addProzeDatawid = [] //要先清空
            this.addProzeData.forEach( (a) => {
                this.addProzeDatawid.push(a.w_id)
                // console.log((a.w_id));
            })
            // console.log(this.addProzeDatawid.length);
            // console.log(this.addProzeDatawid);
            if(this.addProzeDatawid.length <= 0){
                this.$message({
                    type: 'error',
                    showClose: true,
                    message:'添加奖品为空',
                });
            }else{
                if(this.isrepages){ //判断一下一下是否在提交中
                    this.$confirm('roll房商品一旦确定无法修改，请确认您的添加。', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).then(() => {
                            this.isrepages = false
                            this.$axios({
                                url:'roll/set/rollShop',
                                method:'post',
                                data:{
                                    wid:this.addProzeDatawid,
                                    rid:this.$route.query.id
                                }
                            }).then( res => {
                                if(res.data.status == 200){
                                    this.$message({
                                        type: 'success',
                                        showClose: true,
                                        message:res.data.msg
                                    });
                                }else{
                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message:res.data.msg
                                    });
                                }
                                this.outadd()//关闭roll房
                                this.$emit('closepop');
                                this.isrepages = true
                            })
                            
                        }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消添加'
                        });          
                    });
                }
                
            }
        }
    },
    mounted(){
        this.getdatare()
    }

}
</script>

<style <style lang="less" scoped>
.backgHui{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
}
.big{
    width: 50%;
    background-color: #1E1D2A;
    margin:8% auto;
    z-index: 9999;
}
.prize_title{
    display: flex;
    justify-content: space-between;
    padding:10px 20px;
    background-color: #282736;
    align-items: center;
    color: #D0D2E0;
    h1{
        font-size: 20px;
    }
    i{
        font-size: 25px;
        cursor:pointer;
    }
}
.prize_data_stay{
    h1{
        padding: 5px;
    }
}
.prize_data_stay_adddata{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}
.prize_data_stay_ul{
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: scroll;
    height: 200px;
    border-bottom: 1px #fa7633 solid;
}
.prize_data_stay_li{
    background-color: #272729;
    width: 15%;
    margin: 5px 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius:5px 5px 0 0;
    p{
        letter-spacing: 0;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;  /*要显示的行数*/
      -webkit-box-orient: vertical;
      font-size: 14px;
      color: #fff;
      font-weight: bolder;
    }
    span{
        color: #9f9;
        font-size: 20px;
        font-weight: bolder;
    }
}
.prize_data_add_li{
    background-color: #272729;
    width: 15%;
    margin: 5px 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius:5px 5px 0 0;
    p{
        letter-spacing: 0;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;  /*要显示的行数*/
      -webkit-box-orient: vertical;
      font-size: 14px;
      color: #fff;
      font-weight: bolder;
    }
    span{
        color: #9f9;
        font-size: 20px;
        font-weight: bolder;
    }
}
.prize_data_stay_img{
    border-radius:5px 5px 0 0;
    img{
        width: 100%;
    }
}
.prize_data_add{
    h1{
        padding: 5px;
    }
}
.prize_data_add_ul{
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    height: 300px;
    border-bottom: 1px #58442D solid;
}
* ::-webkit-scrollbar {
    width: 5px;
    height: 0;
    background-color: transparent;
}

@media screen and (max-width: 800px) {
    .big{
        width: 95%;
        margin: 20% auto;
    }
    .prize_data_stay_li{
        height: 9rem !important;
    }
    .prize_data_stay_li span{
        font-size: 12px;
    }
    .prize_data_stay_ul{
        height: 10rem !important;
    }
    .prize_data_add_ul{
        height: 15rem;
    }
}
</style>